/****   request.js   ****/
// 导入axios
import axios from 'axios'
import store from "@/vuex/store";
import { getToken } from "@/utils/auth";
//1. 创建新的axios实例，
const service = axios.create({
  // 接口
  baseURL: "https://yzx.yeswedo.com.cn/",
  // baseURL: "http://192.168.0.219/",
  // 超时时间 单位是ms，这里设置了10s的超时时间
  timeout: 20 * 1000
})
// 2.请求拦截器

// http request 拦截器
// service.interceptors.request.use(
//   config => {
//     if (getToken()) {
//       //判断token是否存在
//       config.headers.Authorization = getToken(); //将token设置成请求头
//     }
//     return config;

//   },
//   err => {
//     return Promise.reject(err);
//   }
// );

// let isShowNoLoginPop = false
// 2.请求拦截器
// service.interceptors.request.use(
//   config => {
//     //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
//     config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
//     config.headers = {
//       "Content-Type": "application/json;charset=UTF-8" //配置请求头
//     };
//     //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
//     if (store.state.user.token) {
//       let tk = JSON.parse(JSON.stringify(store.state.user.token))
//       config.headers.Authorization = `ey${tk}`; 
     
//     }
//     return config;

//   }, error => {
//     Promise.reject(error)
//   })

// 3. 响应拦截器
service.interceptors.response.use(
  response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    let res = response.data; // 取到响应返回的数据
    if (res.code == 200) {
      
      // 可能需要返回一些操作成功，删除成功之类的提示，所以把整个信息返回，回调中取得时候记得加一层data
      return res;
    } else if (res.code == 1) {
      return res;
    } 
    else {
      if (res.code === 0 && res.msg === "您已退出登录或已过期") {
        //  Message.error(err);
        // window.vm.$noLogin();
        console.log("未登录",res.msg);
        if(!isShowNoLoginPop) {
          window.vm.$Message.error("未登录即将跳转登录！");
          isShowNoLoginPop = true

          setTimeout(() => { isShowNoLoginPop = false }, 3000)
          setTimeout(() => { window.vm.$router.push({  path: "/" }) }, 2000)
        }
      } else {
      // 状态码如果不是200则为报错，弹出报错信息，且不返回任何数据
        // return Promise.reject(new Error(res || 'Error'))

      return Promise.reject(res);
      }
    }
     return response
  },
  error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          window.location.href = "/NotFound";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "系统维护中，请稍后再试";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        Message.error("服务器响应超时，请刷新当前页");
      }
    }
  }
)
//4.导入文件
export default service