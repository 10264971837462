//获取url参数
// export function geturlparam(val) {
//     console.log("val",val);
//     // window.location.href 获取地址
//     let p = window.location.href.split('?token=')[1]
//     // console.log("p",p);
//     // let params = new URLSearchParams(p)
//     // console.log("params.get(val)",new URLSearchParams(p));
//     return p

// }
export function getQueryVariable() {
    const url = window.location.href
    let obj = {}
    //这个去查找是否,传了参数过来,没有返回空对象
    if (url.indexOf("?") === -1) {
        return obj
    }
    let keyValue = []
    let key = "",
        value = ""
    let paraString = url.substring(url.indexOf("?") + 1, url.length).split("&")
    for (let i in paraString) {
        keyValue = paraString[i].split("=")
        key = keyValue[0]
        value = keyValue[1]
        obj[key] = value
    }
    return obj
}