<template>
  <div class="home p-b-50">
    <div class="w1200 p-t-20">
      <div class="flex-left h-head">
        <div class="p-t-4 hh-left">
          <div
            class="flex-left p-l-20 cur-poin hhl-li"
            v-for="(item, i) in type"
            :key="i"
            @click="typeBtn(item.typId)"
          >
            <img class="img16" :src="navImg[i]?.src" />
            <span class="m-l-12 font14 color333">{{ item.typeName }}</span>
          </div>
        </div>
        <div class="flex-1" style="overflow: hidden">
          <div class="bg">
            <Carousel
              v-model="value3"
              :autoplay="setting.autoplay"
              :autoplay-speed="setting.autoplaySpeed"
              :dots="setting.dots"
              :radius-dot="setting.radiusDot"
              :trigger="setting.trigger"
              :arrow="setting.arrow"
            >
              <CarouselItem v-for="(item, i) in banner" :key="i">
                <div class="demo_carousel">
                  <img class="img_coverImg" :src="item.coverImg" alt="" />
                  <!-- {{item.coverImg}} -->
                </div>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="flex-left m-t-20 h-con">
        <div class="pad20 white-box">
          <div class="flex-between p-t-24">
            <div class="font30 weight color333">特色课程</div>
            <img
              @click="courseList()"
              class="img16 hand"
              src="@/assets/images/icon_15.png"
            />
          </div>
          <div class="flex-between m-t-12">
            <CourseItem
              v-for="(item, i) in handpick"
              :key="i"
              @faBtn="faBtn(item)"
              class="p-b-17"
              :coverImg="item.coverImg"
              :title="item.title"
              :doctoName="item.teacher"
              :introduce="item.label"
              :price="item.salePrice"
              :original="item.markPrice"
              :playNum="item.realPlayCount"
              :free="item.free == 1 ? true : false"
            >
            </CourseItem>
          </div>
        </div>
        <div class="m-l-20">
          <div class="pad20 white-box hc-b2">
            <div class="flex-between p-t-24">
              <div class="font30 weight color333">专家推荐</div>
              <img
                @click="speMore()"
                class="img16 hand"
                src="@/assets/images/icon_15.png"
              />
            </div>
            <div class="flex-between p-t-22 p-b-32">
              <div
                class="flex-col-cen hand hcb-zj"
                v-for="(item, index) in lecturer"
                :key="index"
              >
                <img :src="item.headImg" class="img80" alt="" />
                <div class="font18 color333 weight m-t-16">
                  {{ item.name }}
                </div>
                <div class="font14 color999 m-t-8">{{ item.label }}</div>
                <div
                  class="m-t-10 flex-cen hcb-btn"
                  @click="examineBtn(item.lecturerId)"
                >
                  <div class="font14 colorfff">查看课程</div>
                  <img
                    class="img12 m-l-4"
                    src="@/assets/images/icon_16.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="m-t-20 white-box hc-b2">
            <div class="flex-between pad20 p-t-25 p-b-14">
              <div class="font30 weight color333">知识百科</div>
              <img
                @click="zsBtn()"
                class="img16 hand"
                src="@/assets/images/icon_15.png"
              />
            </div>
            <div class="flex-around p-t-24 hcb-bk">
              <div
                class="flex-col-cen m-b-26 hand"
                v-for="(item, i) in tool"
                :key="i"
                @click="tzBtn(item.toolName, i)"
              >
                <img class="img50" :src="imgList[i].src" alt="" />
                <div class="fong14 color999 m-t-10">{{ item.toolName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-t-20 white-box">
        <div class="flex-between pad20 p-t-24">
          <div class="font30 weight color333">热销课程</div>
          <img
            @click="hotMore()"
            class="img16 hand"
            src="@/assets/images/icon_15.png"
          />
        </div>
        <div class="flex-wrap pad20">
          <div class="mc-li m-r-20" v-for="(item, i) in course" :key="i">
            <CourseItem
              @faBtn="faBtns(item)"
              class="p-b-28"
              :coverImg="item.coverImg"
              :title="item.title"
              :doctoName="item.teacher"
              :introduce="item.label"
              :price="item.salePrice"
              :original="item.markPrice"
              :playNum="item.realPlayCount"
              :free="item.free == 1 ? true : false"
            >
            </CourseItem>
          </div>
        </div>
      </div>
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
import Icon from "../components/Icon.vue";
import CourseItem from "../components/CourseItem.vue";
import { indexApi, checkUser } from "../api/home";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
    Icon,
    CourseItem,
  },
  data() {
    return {
      user: {},
      tokenData: "",
      token: "",
      course: [],
      handpick: [], //特色课程
      banner: [],
      value3: 0,
      setting: {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: "inside",
        radiusDot: false,
        trigger: "click",
        arrow: "hover",
      },
      type: [], //导航栏
      lecturer: [], //专家
      tool: [], //知识百科
      // 导航栏图片
      navImg: [
        { key: 0, src: require("../assets/images/icon_6_.png") },
        { key: 1, src: require("../assets/images/icon_4.png") },
        { key: 2, src: require("../assets/images/icon_5.png") },
        { key: 3, src: require("../assets/images/icon_6.png") },
        { key: 4, src: require("../assets/images/icon_7.png") },
        { key: 5, src: require("../assets/images/icon_8.png") },
        { key: 6, src: require("../assets/images/icon_8.png") },
        { key: 7, src: require("../assets/images/icon_9.png") },
        { key: 8, src: require("../assets/images/icon_10.png") },
      ],
      // 知识百科图片
      imgList: [
        { key: 0, src: require("../assets/images/icon_23.png") },
        { key: 1, src: require("../assets/images/icon_22.png") },
        { key: 2, src: require("../assets/images/icon_24.png") },
        { key: 3, src: require("../assets/images/icon_20.png") },
        { key: 4, src: require("../assets/images/z_1.png") },
        { key: 5, src: require("../assets/images/icon_25.png") },
        { key: 6, src: require("../assets/images/icon_19.png") },
        { key: 7, src: require("../assets/images/icon_21.png") },
      ],
    };
  },

  methods: {
    // getToken() {
    //   // let user = localStorage.getItem("userInfo");
    //   // console.log("user111111111111",user);
    //   // this.user = JSON.parse(user);
    //   $.ajax({
    //     url: "https://v.xasxlyy.com/YiZiXue/GetList",
    //     type: "post",
    //     dataType: "json",
    //     headers: {
    //       Authorization: sessionStorage.getItem("IramePasswordtoken"),
    //     },
    //   })
    //     .then((res) => {
    //       if (res.data) {
    //         localStorage.setItem("userToken", res.data);
    //         checkUser({
    //           token: localStorage.getItem("userToken") || "",
    //         }).then((res) => {
    //           this.userInfo = res.data;
    //           window.parent.postMessage(this.userInfo,"*");
    //           localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    //         });
    //       } else {
    //         checkUser({
    //           token: localStorage.getItem("token") || "",
    //         }).then((res) => {
    //           this.userInfo = res.data;
    //           localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    //         });
    //       }
    //       // this.token = res.data;
    //       // localStorage.setItem("userToken", JSON.stringify(this.token));
    //       // console.log("this.token", res);
    //     })
    //     .fail(function () {
    //       // _this.$Message.error(err);
    //     });
    // },
    // 专家查看课程
    examineBtn(lecturerId) {
      this.$router.push({
        path: "/RecomDetail",
        query: { lecturerId: lecturerId },
      });
    },
    // 跳转
    tzBtn(toolName, i) {
      if (i == 0) {
        this.$router.push({ path: "/Cyclopedia/Edible" });
      } else if (i == 1) {
        this.$router.push({ path: "/Cyclopedia/MeridianPoint" });
      } else if (i == 2) {
        this.$router.push({ path: "/Cyclopedia/Prescription" });
      } else if (i == 3) {
        this.$router.push({ path: "/Cyclopedia/Zwlz" });
      } else if (i == 4) {
        this.$router.push({ path: "/Cyclopedia/Inquire" });
      } else if (i == 5) {
        this.$router.push({ path: "/Cyclopedia/BringUp" });
      } else if (i == 6) {
        this.$router.push({ path: "/Cyclopedia/Beauty" });
      } else if (i == 7) {
        this.$router.push({ path: "/Cyclopedia/LoseWeight" });
      }
    },
    // 知识百科跳转
    zsBtn() {
      this.$router.push({ path: "/Cyclopedia/Edible" });
    },
    // 热销课程跳转
    hotMore() {
      this.$router.push({ path: "/Hot" });
    },
    // 课程跳转更多
    courseList() {
      this.$router.push({ path: "/Feature" });
    },
    // 跳转专家查看更多
    speMore() {
      this.$router.push({ path: "/Recom" });
    },
    faBtn(courseId) {
      console.log("courseId", courseId);
      this.$router.push({ path: "/CourseDetail", query: courseId });
    },
    faBtns(item) {
      this.$router.push({
        path: "/CourseDetail",
        query: item,
      });
    },
    getUserInfo() {
      checkUser({
        token: this.tokenData || localStorage.getItem("token"),
      }).then((res) => {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      });
    },
    getHome() {
      indexApi({ is: 1 }).then((res) => {
        this.banner = res.data.banner;
        this.handpick = res.data.handpick.data;
        this.type = res.data.type;
        this.lecturer = res.data.lecturer;
        this.tool = res.data.tool.reverse();
        this.course = res.data.course.data;
      });
    },
    typeBtn(typId) {
      console.log("typId", typId);
      this.$router.push({
        path: "/category",
        query: { firstTypeId: typId },
      });
    },
  },
  mounted() {
    // isIframe
    // console.log("地址", document.URL);
    let url = document.URL.split("?"); // 去除地址中的参数
    let cs = {};
    if (url[1]) {
      // 有表示存在参数
      url = url[1].split("#/")[0].split("&"); // 先去掉url尾部的#/
      url.forEach((item) => {
        let arr = item.split("=");
        if (arr[0] != "token") {
          arr[1] = decodeURIComponent(arr[1]);
        }
        cs[arr[0]] = arr[1];
      });
      // console.log("处理后的父参数", cs);
      if (cs.isIframe) {
        // 使用 iframe 嵌套
        // 监听postMessage传过来的值;
        window.addEventListener("message", (messageEvent) => {
          let token = messageEvent?.data?.tk;
          // console.log("token", token);
          if (token) {
            this.tokenData = token;
            this.getUserInfo();
          }
          
        });
      } else {
        // 只有使用https访问 -- 龙津
        this.getUserInfo();
      }
    }
    this.getHome();

    // this.$nextTick(() => {
    //   // this.getToken();
    //
    //
    // });
  },
};
</script>

<style scoped lang="scss">
.home {
  background: url(../assets/images/bj_img_1.png) top/100% auto;
  .bg {
    // height: 419px;
    .demo_carousel {
      height: 419px;
      // background-color: #ffa54b;
      .img_coverImg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.h-head {
  .hh-left {
    width: 180px;
    height: 420px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(114, 114, 114, 0.2);
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    .hhl-li {
      width: 180px;
      height: 50px;
      &:hover {
        background: #f9f9f9;
      }
    }
  }
}
.white-box {
  background: #ffffff;
  border-radius: 10px;
}
.h-con {
  & > div {
    width: 590px;
    height: 630px;
  }
  .hcb-zj {
    width: 130px;
    &:hover .hcb-btn {
      background: #ffa54b;
    }
  }
  .hcb-btn {
    width: 110px;
    height: 26px;
    background: #cccccc;
    box-shadow: 0px 2px 4px 0px rgba(171, 171, 171, 0.3);
    border-radius: 13px;
  }
  .hcb-bk {
    & > div {
      width: 120px;
    }
  }
  .hc-b2 {
    height: 305px;
  }
}
</style>
