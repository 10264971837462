<template>
  <div class="main flex-cen">
    <div class="flex-left w1200">
      <div class="font30 color252 weight m-title">医知学专区</div>
      <div class="font16 flex-1 flex-left m-ul">
        <div
          v-for="(item, inx) in list"
          :key="inx"
          class="m-li hand"
          :class="curList == inx ? 'colorffa weight font16' : ''"
          @click="curList = inx"
        >
          <router-link :to="item.path" class="router color252">
            <div>
              {{ item.name }}
            </div>
          </router-link>
        </div>

        <!-- <div class="flex-left m-li">
          <Icon>
            <img src="@/assets/images/icon_1.png" />
          </Icon>
          <span class="m-l-8">返回商城</span>
        </div> -->
      </div>
      <div class="flex-left">
        <div>
          <router-link :to="{ name: 'search' }">
            <Icon>
              <img src="@/assets/images/icon_1.png" />
            </Icon>
          </router-link>
        </div>

        <div class="m-l-32">
          <router-link :to="{ name: 'information' }">
          <Icon>
            <img src="@/assets/images/icon_2.png" />
          </Icon>
          </router-link>
        </div>
        <div class="m-l-30">
          <router-link :to="{ name: 'myInfo' }">
            <Icon w="36">
              <img src="@/assets/images/img_19.png" />
            </Icon>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      curList: 0,
      list: [
        { name: "专区首页", path: "/" },
        { name: "特色课程", path: "/Feature" },
        { name: "热门课程", path: "/Hot" },
        { name: "专家推荐", path: "/Recom" },
        { name: "知识百科", path: "/Cyclopedia/Edible" },
      ],
    };
  },
  components: { Icon },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  width: 100%;
  height: 80px;
  background: #ffffff;
  .router {
  }
  .m-ul {
    margin-left: 130px;
  }
  .m-li {
    &:not(:last-child) {
      margin-right: 60px;
    }
  }
}
a:hover {
  color: #ffa54b;
  font-weight: 600;
  font-size: 16px;
}
</style>
