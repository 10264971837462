import http from "../utils/http"


/*
* 主页
* */
export function indexApi(params) {
   return http.get(`/api/index/index`, params);
}
// 用户资料
export function checkUser(params) {
   return http.post(`/api/index/checkUser`, params);
}
// 课程列表
export function courseList(params) {
   return http.get(`/api/index/course_list`, params);
}
// 讲师列表数据
export function lecturer(params) {
   return http.get(`/api/index/lecturer`, params);
}
// 课程视频列表数据
export function courseVideo(params) {
   return http.get(`/api/index/course_video`, params);
}
// 获取视频播放链接
export function getPlayUrl(params) {
   return http.get(`/api/index/getPlayUrl`, params);
}
// 讲师数据详情
export function lecturerInfo(params) {
   return http.get(`/api/index/lecturer_info`, params);
}
// 搜索
export function search(params) {
   return http.get(`/api/index/search`, params);
}