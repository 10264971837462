<template>
  <div class="flex-cen i-con" :style="{ width: width + 'px', height: height + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    src: {
      type: String,
      default: ''
    },
    w: {
      type: [Number, String]
    },
    h: {
      type: [Number, String]
    },
  },
  data() {
    return {
      width: 20,
      height: 20
    }
  },
  mounted() {
    if(this.w) this.width = this.w
    this.height = this.h || this.w
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .i-con > *{
    width: 100%;
    height: 100%;
  }
</style>
