import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/common.scss";
import ViewUI from 'view-design';
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'vue-awesome-swiper/css/swiper.css'
import 'view-design/dist/styles/iview.css';
import $ from "jquery";
window.jQuery = $;
window.$ = $;

Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.use(VueAwesomeSwiper)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
