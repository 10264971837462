import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import(/* webpackChunkName: "category" */ '../views/Category.vue')
  },
  {
    path: '/CourseDetail',
    name: 'CourseDetail',
    component: () => import(/* webpachChunkNamw: "courseDetail" */ '../views/CourseDetail.vue')
  },
  {
    path: '/OrderInfo',
    name: 'OrderInfo',
    component: () => import('../views/OrderInfo.vue')
  },
  {
    path: '/Feature',
    name: 'Feature',
    component: () => import('../views/Feature.vue')
  },
  {
    path: '/Hot',
    name: 'Hot',
    component: () => import('../views/Hot.vue')
  },
  {
    path: '/Recom',
    name: 'Recom',
    component: () => import('../views/Recom.vue')
  },
  {
    path: '/RecomDetail',
    name: 'RecomDetail',
    component: () => import('../views/RecomDetail.vue')
  },
  {
    path: '/Search',
    name: 'search',
    component: () => import('../views/Search.vue')
  },
  // 个人中心
  {
    path: '/Personal',
    name: 'personal',
    component: () => import("../views/Personal.vue"),
    children: [
      {
        path: "/Personal/myInfo", //我的信息
        name: "myInfo",
        component: () => import("../views/MyInfo.vue")
      },
      {
        path: "/Personal/Purchase", //购买课程
        name: "purchase",
        component: () => import("../views/Purchase.vue")
      },
      {
        path: "/Personal/Study", //学习记录
        name: "study",
        component: () => import("../views/Study.vue")
      },
      {
        path: "/Personal/Information", //我的消息
        name: "information",
        component: () => import("../views/Information.vue")
      },

    ]
  },
  // 知识百科
  {
    path: '/Cyclopedia',
    name: 'cyclopedia',
    component: () => import("../views/Cyclopedia.vue"),
    children: [
      {
        path: "/Cyclopedia/MeridianPoint", //经络穴位
        name: "meridianPoint",
        component: () => import("../views/MeridianPoint.vue")
      },
      {
        path: "/Cyclopedia/Inquire", //中医查询
        name: "inquire",
        component: () => import("../views/Inquire.vue")
      },
      {
        path: "/Cyclopedia/Edible", //食用药膳
        name: "edible",
        component: () => import("../views/Edible.vue")
      },
      {
        path: "/Cyclopedia/Prescription", //方剂查询
        name: "prescription",
        component: () => import("../views/Prescription.vue")
      },
      {
        path: "/Cyclopedia/BringUp", //中医育儿
        name: "bringUp",
        component: () => import("../views/BringUp.vue")
      },
      {
        path: "/Cyclopedia/Beauty", //中医美容
        name: "beauty",
        component: () => import("../views/Beauty.vue")
      },
      {
        path: "/Cyclopedia/LoseWeight", //中医减肥
        name: "loseWeight",
        component: () => import("../views/LoseWeight.vue")
      },
      {
        path: "/Cyclopedia/Zwlz", //子午流柱
        name: "zwlz",
        component: () => import("../views/Zwlz.vue")
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}

export default router
