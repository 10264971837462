<template>
  <div
    class="course-main hand"
    :style="{ width: `${imgw}px` }"
       @click="btnDetail()"
  >
    <div
      class="flex-cen po-rela m-img"
      :style="{ width: `${imgw}px`, height: `${imgh}px` }"
    >
      <img :src="coverImg" alt="课程图片" />
      <div class="flex-cen mi-mask">
        <img class="img40" src="@/assets/images/icon_16.png" alt="" />
      </div>
    </div>
    <div class="m-t-10 pad10 font20 color333 weight m-title over-now">
      {{ title }}
    </div>
    <template v-if="isShowDesc">
      <div class="m-t-8 pad20 font14 color999">
        {{ doctoName }}|{{ introduce }}
      </div>
      <div class="flex-between m-t-8 pad20 font12 colorccc">
        <div class="color22b" v-if="free">免费观看</div>
        <div class="flex-left" v-else>
          <div class="font14 colorff9 weight">¥ {{ price }}</div>
          <div class="m-l-8 text-del">¥ {{ original }}</div>
        </div>
        <div class="flex-left">
          <span class="m-r-6">{{ playNum }}</span>
          <img class="img12" src="@/assets/images/icon_28.png" alt="" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CourseItem",
  props: {
    imgw: {
      type: [String, Number],
    },
    imgh: {
      type: [String, Number],
    },
    isShowDesc: {
      type: Boolean,
      default: true,
    },
    // 是否收费
    free: {
      type: Boolean,
      default: false,
    },
    // 封面图片
    coverImg: {
      type: String,
    },
    title: {
      type: String,
    },
    // 医生名称
    doctoName: {
      type: String,
    },
    // 职称
    introduce: {
      type: String,
    },
    // 现价
    price: {
      type: String,
    },
    // 原价
    original: {
      type: String,
    },
    // 播放次数
    playNum: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods:{
     // 点击进入播放详情
    btnDetail() {
      this.$emit("faBtn")
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.course-main {
  width: 264px;

  .m-img {
    width: 264px;
    height: 165px;
    border-radius: 6px;
    overflow: hidden;
    background: #f2f2f2;
    > img {
      width: 100%;
      height: 100%;
    }
    .mi-mask {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
    }
  }
  &:hover {
    .m-title {
      color: #ffa54b;
    }
    .mi-mask {
      opacity: 1;
    }
  }
}
</style>
